import Resource from '../models/Resource'

export default class SelectedService {
  public placeId: number
  public serviceId: number
  public resourceId: number
  public addonServiceIds: Array<number> = []

  public get effectiveResourceIdAsString(): string {
    return this.resourceId && this.resourceId !== Resource.anyResourceId ? this.resourceId.toString() : ''
  }

}
