import { DateTime } from 'luxon'

export default class Booking {
  public id: number
  public time: DateTime
  public duration: number
  public ticket: string
  public secret: string
  public reservationExpiresAt: DateTime
  public status: string
  public orderId: number
  public tyreHotelId: number
  public wheelChangeId: number
  public isExisting: boolean

  public populate(data: Record<string, unknown>): void {
    if (!data) {
      data = {}
    }
    this.id = (data.id as number) || null
    this.time = DateTime.fromISO(data.time as string) || null
    this.duration = (data.duration as number) || null
    this.ticket = (data.ticket as string) || null
    this.secret = (data.secret as string) || null
    this.status = (data.status as string) || null
    this.orderId = (data.orderId as number) || null
    this.tyreHotelId = (data.tyreHotelId as number) || null
    this.wheelChangeId = (data.wheelChangeId as number) || null
  }

  public clone(): Booking {
    const b = new Booking()
    b.populate(JSON.parse(JSON.stringify(this)))
    return b
  }
}
