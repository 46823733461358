import SelectedService from '../models/SelectedService'
import Booking from '../models/Booking'
import CustomerInfo from '../models/CustomerInfo'
import Reference from '../models/Reference'
import { DateTime } from 'luxon'
import VueAxiosInterface from '@asmadsen/vue-axios/types/VueAxiosInterface'
import Resource from '../models/Resource'

export class CreateBookingError {
  public title: string
  public text: string
}

export default class CreateBooking {
  public static create(
    axios: VueAxiosInterface,
    calendarId: number,
    portalId: number,
    dateTimeStringUtc: string,
    selectedService: SelectedService,
    customer: CustomerInfo,
    reference: Reference,
    isAdmin: boolean,
    callback: (err: CreateBookingError, booking: Booking) => void,
  ): void {
    const data = {
      placeId: selectedService.placeId,
      serviceId: selectedService.serviceId,
      addonServiceIds: selectedService.addonServiceIds,
      time: dateTimeStringUtc,
      calendarId: calendarId,
      portalId: portalId,
      carLicenseplate: customer?.licenseplate,
      contactMobile: customer?.mobilePhone,
      referenceType: reference?.type,
      referenceId: reference?.id,
      wheelChangeSeason: reference?.season,
      resourceId: null,
    }
    if (selectedService.resourceId && selectedService.resourceId !== Resource.anyResourceId) {
      data.resourceId = selectedService.resourceId
    }
    const url = isAdmin ? '/v4/site/calendars/' + calendarId + '/bookings' : '/v4/booking/bookings/reserve'
    axios
      .post(url, data)
      .then((response) => {
        const b = new Booking()
        b.id = response.data.data.id
        b.time = DateTime.fromISO(response.data.data.time, { zone: 'UTC' })
        b.duration = response.data.data.duration
        b.ticket = response.data.data.ticket
        b.secret = response.data.data.secret
        b.reservationExpiresAt = response.data.data.reservationExpiresAt
          ? DateTime.fromISO(response.data.data.reservationExpiresAt, { zone: 'UTC' })
          : null
        b.status = response.data.data.status
        b.orderId = response.data.data.orderId
        b.tyreHotelId = response.data.data.tyreHotelId
        b.wheelChangeId = response.data.data.wheelChangeId
        callback(null, b)
      })
      .catch((err) => {
        const code = err?.response?.data?.error?.code || ''
        const error = new CreateBookingError()
        if (code === 'TimeNotAvailable') {
          error.title = 'c:booking-error:Time no longer available'
          error.text = 'c:booking-error:Somebody else picked this time while you were thinking. Please try again.'
        } else {
          error.title = 'c:booking-error:An error occurred'
          error.text = 'c:booking-error:An unknown error occurred, please try again later.'
        }
        callback(error, null)
      })
  }

  public static update(
    axios: VueAxiosInterface,
    calendarId: number, // not in use?
    dateTimeStringUtc: string,
    selectedService: SelectedService,
    existingBooking: Booking,
    callback: (err: CreateBookingError, booking: Booking) => void,
  ): void {
    const data = {
      placeId: selectedService.placeId,
      serviceId: selectedService.serviceId,
      addonServiceIds: selectedService.addonServiceIds,
      time: dateTimeStringUtc,
      calendarId: calendarId,
      ticket: existingBooking.ticket,
      secret: existingBooking.secret,
      resourceId: null,
    }
    if (selectedService.resourceId && selectedService.resourceId !== Resource.anyResourceId) {
      data.resourceId = selectedService.resourceId
    }
    const url = '/v4/booking/bookings/' + data.ticket + '/' + data.secret + '/modify'
    axios
      .patch(url, data)
      .then((response) => {
        const b = new Booking()
        b.id = response.data.data.id
        b.time = DateTime.fromISO(response.data.data.time, { zone: 'UTC' })
        b.duration = response.data.data.duration
        b.ticket = response.data.data.ticket
        b.secret = response.data.data.secret
        b.status = response.data.data.status
        b.orderId = response.data.data.orderId
        b.tyreHotelId = response.data.data.tyreHotelId
        b.wheelChangeId = response.data.data.wheelChangeId
        callback(null, b)
      })
      .catch((err) => {
        const code = err?.response?.data?.error?.code || ''
        const error = new CreateBookingError()
        if (code === 'TimeNotAvailable') {
          error.title = 'c:booking-error:Time no longer available'
          error.text = 'c:booking-error:Somebody else picked this time while you were thinking. Please try again.'
        } else {
          error.title = 'c:booking-error:An error occurred'
          error.text = 'c:booking-error:An unknown error occurred, please try again later.'
        }
        callback(error, null)
      })
  }

}
